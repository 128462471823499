import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "../../pages/Homepage";
import Art from "../../pages/Art";

function AppRouter() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/art" element={<Art />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AppRouter;

import React from "react";
import LinkList from "../components/linkList/linklist";
import Social from "../components/social/social";

const HomePage = () => {
  return (
    <div>
      <LinkList />;
      <Social />
    </div>
  );
};

export default HomePage;

import React from "react";
import "./navbar.css";
const Navbar = () => {
  return (
    <div className="navbar">
      <ul>
        <li>
          <a href="/">Home </a>
        </li>
        <li>
          <a href="/art"> Art</a>
        </li>
        <li>
          <a href="/tech"> Tech</a>
        </li>
        <li>
          <a href="/tech"> Events </a>
        </li>
        <li>
          <a href="/tech"> Gigs </a>
        </li>
      </ul>
    </div>
  );
};
export default Navbar;

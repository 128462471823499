import React from "react";
import GetData from "../api/getData";
// import { useEffect, useState } from "react/cjs/react.production.min";

const HomePage = () => {
  return (
    <div>
      <GetData />;
    </div>
  );
};

export default HomePage;

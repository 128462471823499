import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles.scss";

import Navbar from "./components/navbar/navbar";
import AppRouter from "./components/router/AppRouter";

function App() {
  return (
    <div id="App">
      <div id="page-wrap"></div>
      <div className="clouds"></div>
      <div className="ballons"></div>
      <Navbar />
      <AppRouter />
    </div>
  );
}
const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

import React from "react";
import "./linklist.css";

function LinkList() {
  return (
    <div className="link-list">
      <div className="link-list-head">
        <div className="image-wrapper">
          <img
            src="https://i1.sndcdn.com/avatars-000157639471-effe7c-t500x500.jpg"
            className="profile-image"
            alt=""
          />
        </div>
        <p className="name-heading">Lingheim</p>
      </div>
    </div>
  );
}

export default LinkList;

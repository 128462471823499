import React, { useState, useEffect } from "react";
import axios from "axios";

const GetData = () => {
  const url =
    "https://data.mongodb-api.com/app/mixdb-nukui/endpoint/getTestdata";
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchKeywords = async () => {
      const data = await fetch(url);

      const jsonData = await data.json();
      setCountries(jsonData.name);
      console.log(jsonData);
    };

    fetchKeywords();
  }, []);
  return (
    <div className="App">
      <h1>Mixtapes</h1>
      <div>
        <ul>
          {countries?.map((name) => (
            <li key={name}>{name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GetData;

import React from "react";
import "./social.css";

const Social = () => {
  return (
    <div class="container">
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.4.1/css/all.css"
      ></link>
      <a
        class="icon"
        target="_blank"
        href="https://www.facebook.com/Lingheim.Approved/"
      >
        <i class="fab fa-facebook-f" />
      </a>
      <a
        class="icon"
        target="_blank"
        href="https://www.instagram.com/lingheimapproved/"
      >
        <i class="fab fa-instagram" />
      </a>
      <a
        class="icon"
        target="_blank"
        href="https://soundcloud.com/lingheim-approved-1"
      >
        <i class="fab fa-soundcloud" />
      </a>
      <a
        class="icon"
        target="_blank"
        href="https://www.mixcloud.com/lingheimapproved/"
      >
        <i class="fab fa-mixcloud" />
      </a>
      <a class="icon" href="mailto:august.lingheim@gmail.com">
        <i class="fas fa-envelope" />
      </a>
    </div>
  );
};
export default Social;
